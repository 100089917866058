#aboutBody {
    height:100%;
    overflow:hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin-top:10px;
    margin-bottom:10px;
    opacity: 0;
    -webkit-animation: fadeIn ease 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn ease 1s; /* Firefox < 16 */
    -ms-animation: fafadeIndein ease 1s; /* Internet Explorer */
    -o-animation: fadeIn ease 1s; /* Opera < 12.1 */
    animation: fadeIn ease 1s;

    animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/

    animation-duration:1s;
    animation-delay: 1.5s
    
}


#aboutBody::-webkit-scrollbar {
    display: none;
}


#aboutHello {
    position:absolute;
    text-align:left;
    font-family:"Raleway", sans-serif;
    letter-spacing: 3px;
    font-weight:200;
    color:white;
    font-size:100px;
    z-index:1000;
    left:10%;
    top:5%;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}
@media screen and (max-width: 600px) {
  #aboutHello {
    width:200px;
    font-size:50px;
  }
}

@media screen and (max-height: 500px) {
  #aboutHello {
    width:200px;
    top:2%;
    font-size:50px;
  }
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

#aboutBodySection1 {
    display: flex;
    position:relative;
    text-align: center;
}


#aboutBackground{
  filter:brightness(0%);
  width:1800px;
}

#aboutBackground.landing{
  animation: landing ease 5s;
  animation-fill-mode: forwards;
}
@keyframes landing {
  0% {transform: translateY(0px)}
  20% {filter:brightness(100%)}
  100% {transform:translateY(-75%); filter:brightness(100%)}
  
}

@media screen and (max-height: 500px) {
  @keyframes landing {
    0% {transform: translateY(0px)}
    20% {filter:brightness(100%)}
    100% {transform:translateY(-84%); filter:brightness(100%)}
    
  }
  }

#spacecraft {
  position:fixed;
  top:67%;
  left:200px;
  z-index:100;
  transform:rotateZ(40deg);
  opacity:0;
}
#spacecraft.spacecraftLanding{
  animation: spacecraftLanding ease 5s;
  animation-fill-mode: forwards;
}


@keyframes spacecraftLanding {
  0% {transform: rotateZ(40deg); opacity:0}
  20% {opacity:1}
  50% {transform:rotateZ(0deg); opacity:1}
  100% {transform:rotateZ(0deg);opacity:1}
}

#reentryBurn {
  position:fixed;
  top:56%;
  left:60px;

  z-index:100;
  opacity:0;
}

@media screen and (min-height: 900px) {
  #reentryBurn  {
    top:60%;
  }
}

@media screen and (max-height: 500px) {
  #spacecraft {
    top:70%;
    
  }
  #reentryBurn  {
    top:55%;
  }
}
#reentryBurn.burnOff {
  animation: burnOff ease 5s;
  animation-fill-mode: forwards;
}

@keyframes burnOff {
  0% {opacity:0}
  25% {opacity:1}
  75% {opacity:0}
  100% {opacity:0}
  
}

#scroll {
  position:fixed;
  bottom:100px;
  left:100px;
}
@media screen and (max-width: 600px) {
  #scroll {
    left:70px;
  }
}

@media screen and (max-height: 500px) {
  #scroll {
    left:65px;
  }
}

@media screen and (min-height: 900px) {
  #scroll {
    bottom:150px;
  }
}




#scroll.vanish {
  animation: vanish ease 1s;
  animation-fill-mode: forwards;
}

@keyframes vanish {
  0% {opacity:1}
  100% {opacity:0}
}

#aboutHello.upNAway {
  animation: upNAwayAnim ease 1.5s;
  animation-fill-mode: forwards;
}

@keyframes upNAwayAnim {
  0% {transform: translateY(0px)}
  100% {transform:translateY(-500px); display:none;}
  
}

#aboutText {
  position:fixed;
  left:10%;
  top:30%;
  color:white;
  width:400px;
  text-align:left;
  font-family:"Overpass", sans-serif;
  font-weight:300;
  opacity:0;
  transition:opacity 5s;
}

@media screen and (max-width: 600px) {
  #aboutText {
    width: max(200px, 60%);
    left:20%;
    top:25%;
  }
}

#aboutText.aboutTextShow {
  animation: aboutTextShow ease 5s;
  animation-fill-mode: forwards;
}

@keyframes aboutTextShow {
  0% {opacity:0}
  90% {opacity:0}
  100% {opacity:1}
  
}
