.outsideLayer {
  margin: 30px 50px;  
  height:83vh;

}
.menuAndName {
  position:center;
}
#menuBar {
  margin-left:auto;
  margin-right:auto;
  border-spacing: 35px 0px;
}

@media screen and (max-width: 600px) {
  #menuBar {
    border-spacing:5px 0px;
  }
  .menuAndName {
    font-size:14px;
  }
  
}
#menuBar th {
  font-family:"Overpass", sans-serif;
  font-weight:300;

}
#name {
  position:absolute;
  left:50px;
  font-family:"Overpass", sans-serif;
  font-weight:600;
}

#logoLinks{
  position:absolute;  
  right:0%;
  padding:5px;
  top:50%;
}
.navLink {
  text-decoration: none;
  color:black;
}
.navLink:visited {
  color:black;
}
.navLink:hover {
  color:rgb(236, 92, 102);
}
.navLink:active {
  color:rgb(236, 92, 102);
}
.intextLink {
  text-decoration: none;
  color:rgb(193, 63, 96);
}
.intextLink:visited {
  color:rgb(193, 63, 96);
}

.intextLink:hover {
  color:rgb(236, 92, 102)
}


