.workBody {
  /* height:100%; */
  height:83vh;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /*Firefox*/
  margin-top:10px;
  margin-bottom:100px;
  overflow-y:auto;
  opacity: 0;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  padding-left:50px;
  padding-right:50px;
  padding-bottom:10px;
  z-index:-1;
  background-color: rgb(250, 250, 250);

  animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  animation-duration:1s;
  animation-delay: 0.5s

}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.workBody::-webkit-scrollbar {
  display: none;
}

#workBodySection {
  padding-bottom:50px;
  display: flex;
}

#workGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  
  grid-gap: 40px;
  padding-top: 10px;
  width:100%;
  margin:auto;
}

@media screen and (max-width: 600px) {
  #workGrid {
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));  
  }
  .workBody{
      padding-left:30px;
      padding-right:30px;
  }

}

#workGrid > div {
  background-color:white;
  text-align: center;
  font-size: 30px;
  height:250px;
  overflow:hidden;
  text-align:center;
}
#workGrid > div:hover .hidden{
  transform: translateY(-100%);
}

.workGridBox {
   cursor: -webkit-grab; 
   cursor: grab;
}
.hidden {
  background-color:rgb(181, 62, 97);
  height: 100%;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  position: relative;
  display: grid;
  justify-items: center;
  align-items: center;
  color:white;
  font-family:"Overpass", sans-serif;
  font-size:20px;
  padding:10px;
  transition: all 0.3s ease-in-out;
}

.workGridImage {
  height:100%;
  width:100%;
  margin:auto;
  display:flex;
  background-position: center;
  background-size:cover;

}

.workGridImage span{
  font-family:"Raleway", sans-serif;
  font-weight:200;
  color:white;
  margin:30px;
}

@media screen and (max-width: 600px){
  .workGridImage span{
    font-size:20px;
    text-align:center;
  }
}


#workBodySection span h1 {
  font-family:"Overpass", sans-serif;
  font-weight:100;
  font-size:50px;
  margin-bottom:0px;
}

#workBodySection span {
  font-family:"Overpass", sans-serif;
  font-weight:100;
  width:700px;
 }

/*** BEGIN POPUP CSS ***/

.popUpWhiteBox {
  position:absolute;
  margin: 0 auto;
  margin-top:0;
  margin-bottom:0;

  background-color:white;
  z-index:20;
  width:800px;
  overflow-y: auto;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding:50px 60px 50px 60px;
  -webkit-box-shadow: 0px 6px 26px -17px rgba(77,77,77,1);
  -moz-box-shadow: 0px 6px 26px -17px rgba(77,77,77,1);
  box-shadow: 0px 6px 26px -17px #4d4d4d;
 }

 .popUpWhiteBox::-webkit-scrollbar {
  display: none;
}

 #test{
  height:10px;
 }

 @media screen and (max-width: 800px) {
  .popUpWhiteBox {
    width:220px;
    max-height:300px;
    overflow-y:auto;
    font-size: 12px;
  }
}

@media screen and (max-height: 500px) {
  .popUpWhiteBox {
    width:380px;
    max-height:200px;
    overflow-y:auto;
  }
}

 #blocker {
  background-color:rgb(173, 173, 173, 0.8);
  position:absolute;
  width:100vw;
  height: 100vh;
  top:0;
  left:0;
  display:none;
  z-index:10;
 }

 .popUpTable {
   position:relative;
   width:100%;
   font-family:"Overpass", sans-serif;
 }
 .popUpTable th {
  width:33%;
}

.popUpTable td {
  font-weight:300;
}
.popUpTable td img {
  display:block;
}
.popUpTable .popUpImage {
  height:350px;
  background-size:cover;
}


#bottomGrad {
  position: absolute;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(226,0,185,1) 0%, rgba(255,255,255,0) 100%);
}
 /*** END POPUP CSS ***/

 #workScroll {
  position:absolute;
  top:60%;
  left:4.5%;
}

@media screen and (min-width: 1500px) {
  #workScroll {
    position:absolute;
    top:76%;
    left:3.5%;
  }
}  


