#gameBody {
    width: 100%;
    height: 100%;
    overflow:hidden;
    position: relative;
    margin-top:10px;
    margin-bottom:10px;
    opacity: 0;
    -webkit-animation: fadeIn ease 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn ease 1s; /* Firefox < 16 */
    -ms-animation: fadeIn ease 1s; /* Internet Explorer */
    -o-animation: fadeIn ease 1s; /* Opera < 12.1 */
    animation: fadeIn ease 1s;

    animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/

    animation-duration:1s;
    animation-delay: 0.5s
  }



#tooSmall {
  width: 100%;
  height: 100%;
  margin-top:10px;
  margin-bottom:10px;
  background-color:black;
  color:white;
  font-family:"Overpass", sans-serif;
  align-items:center;
  line-height:100%;
  display:flex;
  text-align:center;
}

#tooSmallText{
  display: inline-block;
  text-align:center;
  vertical-align: middle;
  padding:30px;
  margin:auto;
}
#game {
    height:100%;
    width:100%;
    position: absolute;

}  

#loading {
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:"Overpass", sans-serif;
    font-size: 200px;
    color: white;
    z-index: 9;
}

#progressBar {
  height: 40%;
  width: 20%;
}

.gone {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
  }
  

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}